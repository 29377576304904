import React from 'react';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Encabezado from './componentes/Encabezado';
import Reportes from './componentes/Reportes';
import Empresa from './componentes/Empresa';
import Usuarios from './componentes/Usuarios';
import Login from './componentes/Login';

export default class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      urlBase:'', //'https://www.cef.isaerpweb.com/',
      lg:false,
      emp:0,
      u:{}
    }
  }
  iniciar = (usu) =>{
    this.setState({
      lg:true, emp : usu.Empresa, u : usu
    });
    const u = JSON.stringify(usu);
    localStorage.setItem("u", u);
  }

  render() {
    const estilo = {visibility : this.state.lg?'visible':'hidden'};
    return (
      <Router>
      { this.state.lg ? <Encabezado u={this.state.u}/> : <Login iniciar={this.iniciar} urlBase={this.state.urlBase}/>}
      <div style={estilo}>
        <Switch>
          <Route exact path="/empresa" render={(props) => <Empresa {...props} u={this.state.u} urlBase={this.state.urlBase}/>}/>
          <Route exact path="/usuarios" render={(props) => <Usuarios {...props} u={this.state.u} urlBase={this.state.urlBase}/>}/>
          <Route exact path="/reportes" render={(props) => <Reportes {...props} u={this.state.u} urlBase={this.state.urlBase}/>}/>
        </Switch>
      </div>
    </Router>
    );
  }
}
