import React from 'react';
import {NavLink} from 'react-router-dom';

export default class Encabezado extends React.Component{
    render(){
        return(
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container">
                <span className="navbar-brand">Cuidemos CDF</span>
                <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                        <NavLink to="/empresa" className="nav-link" >
                            Datos de la empresa
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/usuarios" className="nav-link" >
                            Usuarios
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/reportes" className="nav-link" active>
                            Reportes
                        </NavLink>
                    </li>
                </ul>
                <span style={{color:'white'}} size="sm">{this.props.u.Nombre}</span>
            </div>
        </nav>
        );
    }
}