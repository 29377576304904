import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import Swal from 'sweetalert2'

export default class Login extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            nombre:'cesar',
            pwd:'cesar'
        };
    }
    onChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onSubmit = (e) =>{
        e.preventDefault();
        this.loguear();
    }
    loguear  = async(data) => { //async() => {
        const nom = this.state.nombre;
        const pwd = this.state.pwd;
        const url = `${this.props.urlBase}/usuario/iniciarSesion?usuario=${nom}&pwd=${pwd}`;
        const req = await fetch(url);
        const res = await req.json();
        console.log(res);
        if(res.Id === 0){
            Swal.fire('Atencion','Datos incorrectos','error');
            return;
        }
        this.props.iniciar(res);
    }

    render(){
        return(
            <div className="container">
                <br/><br/><br/><br/><br/>
                <Modal isOpen={true}>
                    <ModalHeader >Cuidemos CDF</ModalHeader>
                    <ModalBody>
                        <form onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group" size="sm">
                                        <label>Usuario</label>
                                        <Input size="sm" type="text" name="nombre" value={this.state.nombre} onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group" size="sm">
                                        <label>Contraseña</label>
                                        <Input size="sm" type="password" name="pwd" value={this.state.pwd} onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                            <input type="submit" className="btn btn-success" value="Iniciar sesion" />
                        </form>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}