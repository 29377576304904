import React from 'react';
import {FormGroup, Input, Label, Button} from 'reactstrap';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';
import { Modal,ModalBody,ModalHeader,ModalFooter } from 'reactstrap';

export default class Reportes extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            mostrandoFotos:false,
            fechaIni:fecIni,
            fechaFin:fecFin,
            fotos:[],
            reportes:[]
        }
    }
    toggle = () => {
        this.setState({mostrandoFotos : !this.state.mostrandoFotos});
      }    
    onChange = (e) =>{
        this.setState({[e.target.name]:e.target.value});
    }
    cargar = async() =>{
        let f1 = this.state.fechaIni;
        let f2 = this.state.fechaFin;
        let fi = f1.substring(0,4) + f1.substring(5,7) + f1.substring(8);
        let ff = f2.substring(0,4) + f2.substring(5,7) + f2.substring(8);
        const url = this.props.urlBase + `reporte/lista?fecIni=${fi}&fecFin=${ff}`;
        const res = await fetch(url);
        const reportes = await res.json();
        this.setState({ reportes });
    }
    imprimir = () =>{
        let f1 = this.state.fechaIni;
        let f2 = this.state.fechaFin;
        let fi = f1.substring(0,4) + f1.substring(5,7) + f1.substring(8);
        let ff = f2.substring(0,4) + f2.substring(5,7) + f2.substring(8);
        const params = `?fecIni=${fi}&fecFin=${ff}`;
        window.open('/reporte/descargar' + params, '', '');
    }
    mostrarFotos = (id,fotos) =>{
        //alert(id + " // fotos : " + fotos)
        const lst = [];
        for(let k = 1; k <= fotos; k++){
            let foto = `https://www.cef.isaerpweb.com/content/fotosReportes/${id}_0${k}.jpg`;
            lst.push({
               src:foto,
               texto:foto
           });
        }
        this.setState({
            mostrandoFotos:true,
            fotos:lst
        });
    }
    render(){
        return(
            <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-2">
                        <FormGroup >
                            <Label>Del dia</Label>
                            <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup >
                            <Label>al dia</Label>
                            <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup >
                            <Label></Label><br />
                            <Button color='success' onClick={this.cargar}>Cargar</Button>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup >
                            <Label></Label><br />
                            <Button color='success' onClick={this.imprimir}>Descargar</Button>
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="container">
                <div>
                    {this.state.reportes.map((e,i)=> (
                        <div>
                            <Reporte data={e} mostrar={this.mostrarFotos} />
                            <br/>
                        </div>
                    ))}
                </div>
            </div>

            <Modal size='lg' isOpen={this.state.mostrandoFotos} toggle={this.toggle} >
                <ModalBody style={{backgroundColor:'#000'}}>
                    <div className="container">
                        <div className="col-12" style={{backgroundColor:'#000'}}>
                            <Fotos fotos={this.state.fotos}/>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            </React.Fragment>
        );
    }
}

function Reporte(props){
    const r = props.data;
    function verFotos(){
        const id = ('' + r.Id).padStart(8,'0');
        props.mostrar(id,r.Fotos);
    }
    return(
        <Card>
            <CardBody>
                <CardTitle>{r.Categoria}</CardTitle>
                <CardSubtitle>{r.Tipo} // {r.SubTipo}</CardSubtitle>
                <CardText>
                    <div className="row">
                        <div className="col-3">
                            Fecha :{r.Fecha}<br/>
                            Hora :{r.Hora}<br/>
                            Lugar :{r.Lugar}<br/>
                        </div>
                        <div className="col-3">
                            Usuario : {r.Usuario}<br/>
                            Fotos : {r.Fotos}<br/>
                            <Button onClick={verFotos}>Ver fotos</Button>
                        </div>
                        <div className="col-6">
                            Descripcion<br/>
                            {r.Descripcion}
                        </div>
                    </div>
                </CardText>
            </CardBody>
        </Card>
    )
}

class Fotos extends React.Component {
    constructor(props) {
      super(props);
      this.state = { activeIndex: 0 };
    }
  
    onExiting = () => {
      this.animating = true;
    }
  
    onExited = () => {
      this.animating = false;
    }
  
    next = () => {
      if (this.animating) return;
      const nextIndex = this.state.activeIndex === this.props.fotos.length - 1 ? 0 : this.state.activeIndex + 1;
      this.setState({ activeIndex: nextIndex });
    }
  
    previous = () => {
      if (this.animating) return;
      const nextIndex = this.state.activeIndex === 0 ? this.props.fotos.length - 1 : this.state.activeIndex - 1;
      this.setState({ activeIndex: nextIndex });
    }
  
    goToIndex = (newIndex) => {
      if (this.animating) return;
      this.setState({ activeIndex: newIndex });
    }
  
    render() {
      const { activeIndex } = this.state;
  
      const slides = this.props.fotos.map((item, index) => {
        return (
          <CarouselItem
            onExiting={this.onExiting}
            onExited={this.onExited}
            key={index}
          >
            <img src={item.src} height={500} alt={item.texto} />
            <CarouselCaption captionText={item.texto} />
          </CarouselItem>
        );
      });
  
      return (
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators items={this.props.fotos} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel>
      );
    }
  }