import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import {Button, Input,FormGroup} from 'reactstrap';

export default class Usuarios extends React.Component{
    usuNuevo = {Id:0,Nombre:'',Pwd:'',EsAdmin:false,Activo:true};

    constructor(props){
        super(props);
        this.state = {
            editando: false,
            usuActual: this.usuNuevo,
            usuarios: []
        };
    }
    componentDidMount = () => {
        this.cargarUsuarios();
    }
    cargarUsuarios = async () => {
        const url = this.props.urlBase + "usuario/lista/";
        const res = await fetch(url);
        const usuarios = await res.json();
        this.setState({ usuarios });
    }
    cargarUsuario = async (cod) => {
        const url = this.props.urlBase + `usuario/wscargar/${cod}`;
        const res = await fetch(url);
        let usuActual = await res.json();
        if(usuActual.Id === 0)
            usuActual = this.usuNuevo;
        usuActual.PwdB = usuActual.Pwd;
        this.setState({ editando: true, usuActual });
    }
    onClickNuevo = () =>{
        this.cargarUsuario(0);
    }
    editar = (cod) => {
        this.cargarUsuario(cod);
    }
    eliminar = (cod) => {
    }
    cancelar = () => {
        this.setState({ editando: false });
    }
    guardar = async(usuario) => {
        const url = this.props.urlBase + "usuario/wsgraba";
        const req = await fetch(url, {
            method: 'POST', 
            body: JSON.stringify(usuario),
          })
        const res = await req.text();
        if(res !== "ok"){
            Swal.fire('Atencion',res,'error');
            return;
        }
        this.setState({editando: false},()=> this.cargarUsuarios());
        Swal.fire('Atencion','Datos guardados.','success');
    }

    render(){
        return(
            <div className="container">
                <h2 className="text-center">Usuarios
                {"   "}
                {this.state.editando ? null: <Button variant="success" onClick={this.onClickNuevo}>+</Button>}
                </h2>
                {this.state.editando ?
                    <UsuarioForm usuario={this.state.usuActual} guardar={this.guardar} cancelar={this.cancelar} />
                :
                    <ListaUsuarios usuarios={this.state.usuarios} editar={this.editar} eliminar={this.eliminar} />
                }
            </div>
        );
    }
}


function ListaUsuarios({ usuarios, editar, eliminar }) {
    return (
        <ul>
            {usuarios.map((item, index) =>
                <UsuarioLista key={index} usuario={item} editar={editar} eliminar={eliminar} />
            )}
        </ul>
    );
}
function UsuarioLista({ usuario, editar, eliminar }) {
    return (
        <li className="list-group-item d-flex justify-content-between align-items-center" >
            <p>
                {' . '}
                <Input size="sm" type="checkbox" name="Activo" checked={usuario.Activo} />{' '}
                {' '}
                <span>{usuario.Nombre}</span>
            </p>
            <div>
                <button className="btn btn-success" onClick={() => editar(usuario.Id)}>Editar</button>
                {' '}
                <button className="btn btn-primary" onClick={() => eliminar(usuario.Id)}>Eliminar</button>
            </div>
        </li>
    )
}

function UsuarioForm(props) {
    const [usuario, guardarUsuario] = useState(props.usuario);

    function onChange(e) {
        const estado = {
            ...usuario,
            [e.target.name]: e.target.value
        };
        guardarUsuario(estado);
    }
    function onCheck(e) {
        const estado = {
            ...usuario,
            [e.target.name]: e.target.checked
        };
        guardarUsuario(estado);
    }
    function onSubmit(e) {
        e.preventDefault();
        console.log(usuario);
        if(usuario.Nombre === ''){
            Swal.fire('Atencion',"Indique el nombre del usuario",'error');
            return;
        }
        if(usuario.Pwd === '' || (usuario.Pwd !== usuario.PwdB)){
            Swal.fire('Atencion',"La contraseña es incorrecta",'error');
            return;
        }
        const MySwal = withReactContent(Swal)


        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                props.guardar(usuario);
            }
          })        
        //MySwal.fire(<p>Shorthand works too</p>);
    }
    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col">
                    <div className="form-group" size="sm">
                        <label>Nombre</label>
                        <Input size="sm" type="text" name="Nombre" value={usuario.Nombre} onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>Contraseña</label>
                        <Input size="sm" type="password" name="Pwd" value={usuario.Pwd} onChange={onChange} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>Confirmar</label>
                        <Input size="sm" type="password" name="PwdB" value={usuario.PwdB} onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <FormGroup check>
                        <label check>
                            <Input size="sm" type="checkbox" name="Activo" checked={usuario.Activo} onChange={onCheck} />{' '}
                            Activo<br/>
                        </label>
                    </FormGroup>
                </div>
                <div className="col-3">
                    <FormGroup check>
                        <label check>
                            <Input size="sm" type="checkbox" name="EsAdmin" checked={usuario.EsAdmin} onChange={onCheck} />{' '}
                            Administrador<br/>
                        </label>
                    </FormGroup>
                </div>
            </div>
            <br/><br/>
            <input type="submit" className="btn btn-success" value="Guardar" />
            {' '}
            <button className="btn btn-primary" onClick={props.cancelar}>Cancelar</button>
        </form>
    )
}